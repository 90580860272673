import React, { FC, useCallback } from 'react';
import { View, Text, StyleSheet, Button } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { RootStackScreenProps } from '../types';
import { observer } from 'mobx-react-lite';
import { useInstance } from 'react-ioc';
import { Store } from '../model/store/Store';
import { useFocusEffect } from '@react-navigation/native';


const ScanResultScreen: FC<RootStackScreenProps<'ScanResult'>> = ({ navigation, route }) => {
  const { data, type } = route.params;

  const store = useInstance(Store);

  const { deviceVars: { table } } = store;

  const isSameTable = table === data.table;

  useFocusEffect(useCallback(() => {
    if (type === 'pay' && isSameTable) {
      navigation.replace('Pay');
    }
  }, [type, isSameTable]));

  if (type === 'pay' && isSameTable) {
    return null;
  }

  if (type === 'pay' && !isSameTable) {
    return (
      <SafeAreaView style={s.root}>
        <View style={s.container}>
          <View style={{ marginBottom: 40 }}>
            <Text style={{ marginBottom: 20 }}>У вас нет заказа за этим столиком</Text>
          </View>

          <View>
            <View style={{ marginBottom: 40 }}>
              <Button onPress={() => {
                navigation.navigate('Initial');
              }} title="Назад" />
            </View>
          </View>
        </View>
      </SafeAreaView>
    );
  }

  return (
    <SafeAreaView style={s.root}>
      <View style={s.container}>
        <View style={{ marginBottom: 40 }}>
          <Text style={{ marginBottom: 20 }}>Добро пожаловать!</Text>
          <Text>Ваш официант: {data.waiter}</Text>
          <Text>Номер столика: {data.table}</Text>
        </View>

        <View>
          <View style={{ marginBottom: 40 }}>
            <Button onPress={() => {
              store.deviceVars.setTable(data.table);
              store.deviceVars.setWaiter(data.waiter);
              navigation.replace('Menu');
            }} title="Перейти в меню" />
          </View>
          <Button onPress={navigation.goBack} title="Отмена" />
        </View>
      </View>
    </SafeAreaView>
  );
};

const s = StyleSheet.create({
  root: {
    flex: 1,
  },
  container: {
    padding: 20,
    flex: 1,
    justifyContent: 'center',
  },
});

export default observer(ScanResultScreen);
