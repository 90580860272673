import { BarCodeScanner } from 'expo-barcode-scanner';
import { Camera } from 'expo-camera';
import { View, Text, Button, Linking, StyleSheet, Pressable, Alert, Modal } from 'react-native';
import { FC, useCallback, useEffect, useState } from 'react';
import { useFocusEffect } from '@react-navigation/native';
import React from 'react';
import { ScanData, ScanType } from '../types';
import { useInstance } from 'react-ioc';
import { Store } from '../model/store/Store';
import { observer } from 'mobx-react-lite';


interface IProps {
  type: ScanType;
  navigation: any,
}

const QrCodeScanner: FC<IProps> = ({ navigation, type }) => {
  const [hasPermission, setHasPermission] = useState(null);
  const [scanned, setScanned] = useState(false);
  const [data, setData] = useState<ScanData | undefined>();

  const { deviceVars: { setTable, setWaiter } } = useInstance(Store);

  useFocusEffect(
    useCallback(() => {
      setScanned(false);
      setData(undefined);

      (async () => {
        const { status } = await BarCodeScanner.requestPermissionsAsync();
        setHasPermission(status === 'granted');
      })();
    }, [setScanned, setData, setHasPermission]),
  );

  const handleBarCodeScanned = ({ data }) => {
    console.log('handleBarCodeScanned data', data);

    try {
      const url = new URL(data);
      const params = new URLSearchParams(url.search);
      const hasParams = params.has('table') && params.has('waiter') && params.has('scanned');

      if (hasParams) {
        const table = params.get('table');
        const waiter = params.get('waiter');

        setScanned(true);
        setTable(table);
        setWaiter(waiter);

        params.delete('scanned');

        window.location.search = `?${params.toString()}`;

        navigation.reset({
          index: 1,
          routes: [
            { name: 'Menu' },
          ],
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  if (hasPermission === null) {
    return (
      <View style={{ padding: 20 }}>
        <Text style={{ color: 'white' }}>
          Для работы приложения необходимо дать доступ к камере.
        </Text>
      </View>
    );
  }

  if (hasPermission === false) {
    return (
      <View style={{ padding: 20 }}>
        <Text style={{ color: 'white' }}>
          Приложению запрещен доступ к камере, работа не возможна.
        </Text>
        <Button
          onPress={Linking.openSettings}
          title={'Открыть доступ к камере в настройках'}
        />
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <Camera
        style={StyleSheet.absoluteFillObject}
        barCodeScannerSettings={{
          barCodeTypes: [BarCodeScanner.Constants.BarCodeType.qr],
        }}
        onBarCodeScanned={scanned ? undefined : handleBarCodeScanned}
      />
    </View>
  );
};


const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
  },

  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    backgroundColor: '#00000099',
  },
  modalView: {
    width: '80%',
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center',
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  buttonOpen: {
    backgroundColor: '#F194FF',
  },
  buttonClose: {
    backgroundColor: '#2196F3',
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
});

export default observer(QrCodeScanner);
