import {
  Instance,
  SnapshotIn,
  SnapshotOut,
  types as t,
} from 'mobx-state-tree';
import { DeviceVarsStore } from './DeviceVarsStore';
import { CartStore } from './CartStore';

export const RootStore = t
  .model({
    hydrated: t.optional(t.boolean, false),
    deviceVars: t.optional(DeviceVarsStore, {}),
    cart: t.optional(CartStore, {}),
  })
  .actions(self => ({
    setHydrated: (value: boolean): void => {
      self.hydrated = value;
    },
  }));


export interface IRootStoreModel extends Instance<typeof RootStore> {}
export interface IRootStoreModelSnapshotIn extends SnapshotIn<typeof RootStore> {}
export interface IRootStoreModelSnapshotOut extends SnapshotOut<typeof RootStore> {}
