import React, { FC } from 'react';
import { RootStackScreenProps } from '../types';
import QrCodeScanner from '../components/QrCodeScanner';


const ScanScreen: FC<RootStackScreenProps<'Scan'>> = ({ navigation, route }) => {
  const { type } = route.params;

  return <QrCodeScanner type={type} navigation={navigation}/>;
};

export default ScanScreen;
