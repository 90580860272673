import {
  applySnapshot,
  Instance,
  SnapshotIn,
  SnapshotOut,
  types as t,
} from 'mobx-state-tree';
import uniq from 'lodash/uniq';

export const CartStore = t
  .model({
    itemsInCart: t.optional(t.array(t.number), []),
  })
  .actions((self) => ({
    addToCart: (itemId: number) => {
      self.itemsInCart.push(itemId);

      applySnapshot(self.itemsInCart, [...self.itemsInCart])
    },
    clearCart: () => {
      applySnapshot(self.itemsInCart, [])
    },
    removeFromCart: (itemId: number) => {
      const newCart = self.itemsInCart.filter(id => id !== itemId);
      applySnapshot(self.itemsInCart, newCart)
    }
  }));

export interface ICartStore extends Instance<typeof CartStore> {}
export interface ICartStoreSnapshotIn extends SnapshotIn<typeof CartStore> {}
export interface ICartStoreSnapshotOut extends SnapshotOut<typeof CartStore> {}
