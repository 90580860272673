import { applySnapshot, Instance } from 'mobx-state-tree';
import { IRootStoreModel, RootStore } from './RootStore';
import persist from 'mst-persist';
import AsyncStorage from '@react-native-async-storage/async-storage';

const STORE_IN_LOCAL_STORAGE_KEY = 'mobile-menu-store';

export class Store {
  static create(): IRootStoreModel {
    const StoreInstance = RootStore.create({});

    persist(
      STORE_IN_LOCAL_STORAGE_KEY,
      StoreInstance,
      {
        storage: AsyncStorage,
        jsonify: true,
        whitelist: [
          'deviceVars',
          'cart',
        ],
      },
    ).finally(() => {
      StoreInstance.setHydrated(true);
    }).catch(error => {
      console.error(error);

      applySnapshot(StoreInstance, {});
      StoreInstance.setHydrated(true);
    });

    return StoreInstance;
  }
}


export interface Store extends Instance<typeof RootStore> {}
