import { FC } from 'react';
import { View, Text, Image, Button } from 'react-native';
import { useInstance } from 'react-ioc';
import { Store } from '../model/store/Store';
import { observer } from 'mobx-react-lite';

export type MenuItemType = {
  name: string,
  image: string,
  price: number,
}

export const menuItems: MenuItemType[] = [
  {
    name: 'Слайсы из свинины с соусом',
    image: 'https://shashlikoff.com/upload/resize_cache/iblock/241/268_195_1/Svinye-slaysy-pod-slivochno_gribnym-sousom.jpg',
    price: 459,
  },
  {
    name: 'Ассорти-гриль Small',
    image: 'https://shashlikoff.com/upload/resize_cache/iblock/a4d/268_195_1/Assorti-gril-smal.jpg',
    price: 449,
  },
  {
    name: 'Ассорти-гриль Big',
    image: 'https://shashlikoff.com/upload/resize_cache/iblock/b46/268_195_1/Assorti-gril-Big.jpg',
    price: 599,
  },
  {
    name: 'Колбаски чикен-гриль',
    image: 'https://shashlikoff.com/upload/resize_cache/iblock/f49/268_195_1/f498dcb8465aab48907cc5c438b88589.JPG',
    price: 369,
  },
];

interface IProps {
  itemNumber: number,
}

const MenuItem: FC<IProps> = ({ itemNumber }) => {
  const store = useInstance(Store);

  const { cart: { itemsInCart, addToCart, removeFromCart } } = store;

  const item = menuItems[itemNumber];
  const inCart = itemsInCart.includes(itemNumber);

  return (
    <View style={{
      margin: 10,
      backgroundColor: !inCart ? '#F5F5F5' : '#a8e0a9',
      padding: 20,
      borderRadius: 10,
    }}>
      <View>
        <Text style={{ fontSize: 20, marginBottom: 10 }}>
          {item.name}
        </Text>
      </View>
      <View style={{ marginBottom: 10 }}>
        <Image source={{ uri: item.image }} style={{ width: '100%', height: 300 }} />
      </View>
      <View>
        <Text style={{ fontSize: 20, textAlign: 'right' }}>{item.price}р.</Text>
      </View>
      <View style={{ marginTop: 10 }}>
        {!inCart && (
          <Button title="Добавить в заказ" onPress={() => addToCart(itemNumber)} />
        )}
        {inCart && (
          <Button title="Убрать из заказа" onPress={() => removeFromCart(itemNumber)} />
        )}
      </View>
    </View>
  );
};

export default observer(MenuItem);
