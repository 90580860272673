import React, { FC } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, ScrollView } from 'react-native';
import { RootStackScreenProps } from '../types';
import { observer } from 'mobx-react-lite';
import { useInstance } from 'react-ioc';
import { Store } from '../model/store/Store';
import { menuItems } from '../components/MenuItem';
import moment from 'moment';
import tgMessage from '../model/tgMessage';

const OrderScreen: FC<RootStackScreenProps<'Order'>> = ({ navigation }) => {
  const {
    deviceVars: {
      waiter,
      table,
      setOrdered,
    },
    cart: {
      itemsInCart,
    },
  } = useInstance(Store);


  const sum = itemsInCart.reduce((acc, itemId) => acc + menuItems[itemId].price, 0);

  const makeOrder = () => {
    const text = `
Новый заказ

Время заказа ${moment().format('HH:mm')}
Столик ${table}
Официант ${waiter}

Состав заказа
${itemsInCart.map(itemId => `${menuItems[itemId].name} 1шт.`).join('\n')}
`;
    tgMessage(encodeURIComponent(text));

    setOrdered(true);
    navigation.reset({
      index: 1,
      routes: [
        { name: 'OrderResult' },
      ],
    });
  };

  return (
    <ScrollView contentContainerStyle={s.root} alwaysBounceVertical={false}>
      <View style={s.container}>
        <View style={{ marginBottom: 40 }}>
          <Text style={{ paddingBottom: 20, fontSize: 20, fontWeight: 'bold' }}>Номер столика: {table}</Text>
          <Text style={{ paddingBottom: 20, fontSize: 20 }}>Ваш официант: {waiter}</Text>
          <Text style={{ marginTop: 20, paddingBottom: 10, fontSize: 20, fontWeight: 'bold' }}>Состав заказа</Text>
          {itemsInCart.map((itemId) => (
            <Text key={itemId} style={{ paddingBottom: 10, fontSize: 20 }}>
              - {menuItems[itemId].name} {menuItems[itemId].price}р.
            </Text>
          ))}
          <Text style={{ paddingBottom: 10, fontSize: 20, fontWeight: 'bold' }}>Сумма: {sum}р.</Text>
        </View>
        <TouchableOpacity onPress={makeOrder}>
          <View style={s.button}>
            <Text style={s.text}>
              Сделать заказ
            </Text>
          </View>
        </TouchableOpacity>
      </View>
    </ScrollView>
  );
};


const s = StyleSheet.create({
  root: {},
  container: {
    padding: 20,
    flex: 1,
    justifyContent: 'center',
  },
  button: {
    backgroundColor: '#1890ff',
    borderRadius: 20,
    padding: 20,
  },
  buttonDisabled: {
    opacity: 0.3,
    backgroundColor: '#8a8989',
  },
  text: {
    color: '#fff',
    textAlign: 'center',
    fontSize: 30,
  },
});

export default observer(OrderScreen);
