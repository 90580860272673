import React, { FC, useEffect } from 'react';
import { View, StyleSheet, ScrollView, Button, Text } from 'react-native';
import { RootStackScreenProps } from '../types';
import MenuItem from '../components/MenuItem';
import { observer } from 'mobx-react-lite';
import { useInstance } from 'react-ioc';
import { Store } from '../model/store/Store';
import { useSafeAreaInsets } from 'react-native-safe-area-context';


const MenuScreen: FC<RootStackScreenProps<'Menu'>> = ({ navigation }) => {
  const { deviceVars: { table, waiter }, cart: { itemsInCart } } = useInstance(Store);
  const insets = useSafeAreaInsets();

  useEffect(() => {
    navigation.setOptions({ title: `Стол: ${table}\nОфициант: ${waiter}` });
  }, [table, waiter]);

  return (
    <View style={s.root}>
      <ScrollView contentContainerStyle={[s.scroll, {
        paddingBottom: 100 + insets.bottom,
      }]} style={{ flex: 1 }}>
        <MenuItem itemNumber={0} />
        <MenuItem itemNumber={1} />
        <MenuItem itemNumber={2} />
        <MenuItem itemNumber={3} />
      </ScrollView>
      <View style={{
        position: 'absolute',
        bottom: 0,
        width: '100%',
        height: 90 + insets.bottom,
        backgroundColor: '#fff',
        padding: 20,
        paddingBottom: insets.bottom + 10,
        borderTopWidth: 1,
      }}>
        {itemsInCart.length > 0 && (
          <Button title={`Сделать заказ (${itemsInCart.length})`} onPress={() => {
            navigation.navigate('Order');
          }} />
        )}

        {itemsInCart.length === 0 && (
          <Text style={{textAlign: 'center'}}>Добавьте блюда в заказ</Text>
        )}
      </View>
    </View>
  );
};

const s = StyleSheet.create({
  root: {
    flex: 1,
  },
  scroll: {
    padding: 20,
  },
});

export default observer(MenuScreen);
