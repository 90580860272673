import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const ArrowLeftSvg = () => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <Path
        d="M7.82794 10.9999H19.9999V12.9999H7.82794L13.1919 18.3639L11.7779 19.7779L3.99994 11.9999L11.7779 4.22192L13.1919 5.63592L7.82794 10.9999Z"
        fill="#000"/>
    </Svg>
  );
};

export default ArrowLeftSvg;

