import { FC, useCallback, useMemo } from 'react';
import * as React from 'react';
import { createNativeStackNavigator, NativeStackNavigationOptions } from '@react-navigation/native-stack';
import { RootStackParamList } from '../types';
import { Image, Platform, View } from 'react-native';
import InitialScreen from '../screens/InitialScreen';
import MenuScreen from '../screens/MenuScreen';
import ScanScreen from '../screens/ScanScreen';
import ScanResultScreen from '../screens/ScanResultScreen';
import Header from '../components/Header/Header';
import OrderScreen from '../screens/OrderScreen';
import OrderResultScreen from '../screens/OrderResultScreen';
import PayResultScreen from '../screens/PayResultScreen';
import PayScreen from '../screens/PayScreen';
import { observer } from 'mobx-react-lite';
import { useInstance } from 'react-ioc';
import { Store } from '../model/store/Store';
import { useFocusEffect } from '@react-navigation/native';

const RootStack = createNativeStackNavigator<RootStackParamList>();

const screenOptions: NativeStackNavigationOptions = {
  headerTintColor: '#232323',
  headerBackTitle: '',
  animation: Platform.OS === 'android' ? 'slide_from_right' : undefined,
  header: Header,
  headerRight: () => <View style={{ paddingRight: 20 }}><Image style={{width: 30, height: 30, resizeMode: 'contain'}}  source={require('./../assets/logo.png')} /></View>,
};

const RootNavigator: FC = () => {
  const {
    deviceVars: {
      setTable,
      setWaiter,
      table,
      waiter,
      ordered,
    },
  } = useInstance(Store);

  const params = new URLSearchParams(window.location.search);
  const hasParams = params.has('table') && params.has('waiter') && params.has('scanned');

  useFocusEffect(useCallback(() => {
    if (hasParams) {
      const table = params.get('table');
      const waiter = params.get('waiter');

      setTable(table);
      setWaiter(waiter);

      params.delete('scanned');

      window.location.search = `?${params.toString()}`;
    }
  }, [params, hasParams]));

  const haveTableAndWaiter = !!table && !!waiter;

  const initialRouteName = useMemo(() => {
    if (haveTableAndWaiter && !ordered) {
      return 'Menu';
    }

    if (haveTableAndWaiter && ordered) {
      return 'Pay';
    }

    return 'Initial';
  }, [haveTableAndWaiter, ordered])

  return (
    <RootStack.Navigator
      initialRouteName={initialRouteName}
      screenOptions={screenOptions}
    >
      <RootStack.Screen
        name="Initial"
        component={InitialScreen}
        options={{
          headerShown: false,
        }}
      />

      <RootStack.Screen
        name="Menu"
        component={MenuScreen}
        options={{
          title: 'Меню',
        }}
      />

      <RootStack.Screen
        name="Scan"
        component={ScanScreen}
        options={{
          title: 'Отсканируйте QR код на столике',
        }}
      />

      <RootStack.Screen
        name="ScanResult"
        component={ScanResultScreen}
        options={{
          title: 'Результат сканирования',
        }}
      />

      <RootStack.Screen
        name="Order"
        component={OrderScreen}
        options={{
          title: 'Оформление заказа',
        }}
      />

      <RootStack.Screen
        name="OrderResult"
        component={OrderResultScreen}
        options={{
          title: 'Ваш заказ',
        }}
      />

      <RootStack.Screen
        name="PayResult"
        component={PayResultScreen}
        options={{
          title: 'Заказ оплачен',
        }}
      />

      <RootStack.Screen
        name="Pay"
        component={PayScreen}
        options={{
          title: 'Оплата заказа',
        }}
      />
    </RootStack.Navigator>
  );
};

export default observer(RootNavigator);
