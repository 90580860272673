import { FC } from 'react';
import { DefaultTheme, NavigationContainer } from '@react-navigation/native';
import { navigationRef } from './navigationRef';
import * as React from 'react';
import RootNavigator from './RootNavigator';

const NavigationTheme = {
  ...DefaultTheme,
  dark: true,
  colors: {
    ...DefaultTheme.colors,
    background: '#fff',
  },
};

const Navigation: FC = () => {
  return (
    <NavigationContainer
      theme={NavigationTheme}
      ref={navigationRef}
    >
      <RootNavigator />
    </NavigationContainer>
  );
};

export default Navigation;
