import React, { FC } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, ScrollView } from 'react-native';
import { RootStackScreenProps } from '../types';
import { observer } from 'mobx-react-lite';
import { useInstance } from 'react-ioc';
import { Store } from '../model/store/Store';
import { menuItems } from '../components/MenuItem';



const OrderResultScreen: FC<RootStackScreenProps<'OrderResult'>> = ({ navigation }) => {
  const {
    deviceVars: {
      waiter,
      table,
      name,
      phone,
    },
    cart: {
      itemsInCart,
    },
  } = useInstance(Store);

  const sum = itemsInCart.reduce((acc, itemId) => acc + menuItems[itemId].price, 0);

  return (
    <ScrollView contentContainerStyle={s.root} alwaysBounceVertical={false}>
      <View style={s.container}>
        <View style={{ marginBottom: 40 }}>
          <Text style={{ paddingBottom: 20, fontSize: 20, fontWeight: 'bold' }}>
            Спасибо за заказ!
          </Text><Text style={{ paddingBottom: 20, fontSize: 20, fontWeight: 'bold' }}>
            Заказ передан на кухню, время готовности 20 минут
          </Text>
        </View>
        <View style={{ marginBottom: 40 }}>
          <Text style={{ paddingBottom: 20, fontSize: 20, fontWeight: 'bold' }}>Номер столика: {table}</Text>
          <Text style={{ paddingBottom: 20, fontSize: 20 }}>Ваш официант: {waiter}</Text>
          <Text style={{ marginTop: 20, paddingBottom: 10, fontSize: 20, fontWeight: 'bold' }}>Состав заказа</Text>
          {itemsInCart.map((itemId) => (
            <Text key={itemId} style={{ paddingBottom: 10, fontSize: 20 }}>
              - {menuItems[itemId].name} {menuItems[itemId].price}р.
            </Text>
          ))}
          <Text style={{ paddingBottom: 10, fontSize: 20, fontWeight: 'bold' }}>Сумма: {sum}р.</Text>
        </View>
        <View style={{ marginBottom: 40 }}>
          <Text style={{ paddingBottom: 20, fontSize: 20, fontWeight: 'bold' }}>Имя: {name}</Text>
          <Text style={{ paddingBottom: 20, fontSize: 20 }}>Телефон: {phone}</Text>
        </View>
        <TouchableOpacity onPress={() => {
          navigation.replace('Pay');
        }}>
          <View style={s.button}>
            <Text style={s.text}>
              Отлично
            </Text>
          </View>
        </TouchableOpacity>
      </View>
    </ScrollView>
  );
};


const s = StyleSheet.create({
  root: {},
  container: {
    padding: 20,
    flex: 1,
    justifyContent: 'center',
  },
  button: {
    backgroundColor: '#1890ff',
    borderRadius: 20,
    padding: 20,
  },
  buttonDisabled: {
    opacity: 0.3,
    backgroundColor: '#8a8989',
  },
  text: {
    color: '#fff',
    textAlign: 'center',
    fontSize: 30,
  },
});

export default observer(OrderResultScreen);
