import React, { FC } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, ScrollView } from 'react-native';
import { RootStackScreenProps } from '../types';
import { observer } from 'mobx-react-lite';
import { useInstance } from 'react-ioc';
import { Store } from '../model/store/Store';
import { menuItems } from '../components/MenuItem';



const PayResultScreen: FC<RootStackScreenProps<'OrderResult'>> = ({ navigation }) => {
  const {
    deviceVars: {
      name,
    }
  } = useInstance(Store);

  return (
    <ScrollView contentContainerStyle={s.root} alwaysBounceVertical={false}>
      <View style={s.container}>
        <View style={{ marginBottom: 40 }}>
          <Text style={{ paddingBottom: 20, fontSize: 20, fontWeight: 'bold' }}>
            {name}, ваш заказ оплачен!
          </Text>
        </View>
        <TouchableOpacity onPress={() => {
          navigation.navigate('Menu');
        }}>
          <View style={s.button}>
            <Text style={s.text}>
              Отлично
            </Text>
          </View>
        </TouchableOpacity>
      </View>
    </ScrollView>
  );
};


const s = StyleSheet.create({
  root: {},
  container: {
    padding: 20,
    flex: 1,
    justifyContent: 'center',
  },
  button: {
    backgroundColor: '#1890ff',
    borderRadius: 20,
    padding: 20,
  },
  buttonDisabled: {
    opacity: 0.3,
    backgroundColor: '#8a8989',
  },
  text: {
    color: '#fff',
    textAlign: 'center',
    fontSize: 30,
  },
});

export default observer(PayResultScreen);
