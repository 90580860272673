import React, { FC, useCallback } from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { RootStackScreenProps } from '../types';
import { useInstance } from 'react-ioc';
import { Store } from '../model/store/Store';
import { observer } from 'mobx-react-lite';


const InitialScreen: FC<RootStackScreenProps<'Initial'>> = ({ navigation }) => {
  const {
    deviceVars: {
      ordered,
    },
  } = useInstance(Store);

  return (
    <SafeAreaView style={s.root}>
      <View style={s.container}>
        <TouchableOpacity
          onPress={() => {
            navigation.navigate('Scan', { type: 'order' });
          }}>
          <View style={s.button}>
            <Text style={s.text}>
              Сканировать QR код на столике
            </Text>
          </View>
        </TouchableOpacity>
      </View>
    </SafeAreaView>
  );
};


const s = StyleSheet.create({
  root: {
    flex: 1,
  },
  container: {
    padding: 20,
    flex: 1,
    justifyContent: 'center',
  },
  button: {
    backgroundColor: '#1890ff',
    borderRadius: 20,
    padding: 20,
    margin: 20,
  },
  text: {
    color: '#fff',
    textAlign: 'center',
    fontSize: 30,
  },
});

export default observer(InitialScreen);
