import {
  Instance,
  SnapshotIn,
  SnapshotOut,
  types as t,
} from 'mobx-state-tree';


export const DeviceVarsStore = t
  .model({
    table: t.optional(t.string, ''),
    waiter: t.optional(t.string, ''),
    name: t.optional(t.string, ''),
    phone: t.optional(t.string, ''),
    ordered: t.optional(t.boolean, false),
  })
  .actions((self) => ({
    setTable: (value: string) => {
      self.table = value;
    },
    setWaiter: (value: string) => {
      self.waiter = value;
    },
    setName: (value: string) => {
      self.name = value;
    },
    setPhone: (value: string) => {
      self.phone = value;
    },
    setOrdered: (value: boolean) => {
      self.ordered = value;
    },
  }));

export interface IDeviceVarsStore extends Instance<typeof DeviceVarsStore> {}
export interface IDeviceVarsStoreSnapshotIn extends SnapshotIn<typeof DeviceVarsStore> {}
export interface IDeviceVarsStoreSnapshotOut extends SnapshotOut<typeof DeviceVarsStore> {}
