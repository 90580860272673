import { FC } from 'react';
import * as React from 'react';

import { NativeStackHeaderProps } from '@react-navigation/native-stack/lib/typescript/src/types';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { StyleSheet, View } from 'react-native';
import HeaderBackButton from './HeaderBackButton';
import HeaderTitle from './HeaderTitle';
import { BottomTabHeaderProps } from '@react-navigation/bottom-tabs/lib/typescript/src/types';

export const headerHeight = 60;

interface IForceHideBackButton {
  forceHideBackButton?: boolean;
  onBackButtonPress?: () => void;
}

interface IPropsStack extends NativeStackHeaderProps, IForceHideBackButton {

}

interface IPropsBottomTab extends BottomTabHeaderProps, IForceHideBackButton {

}

const Header: FC<IPropsStack | IPropsBottomTab> = (props) => {
  const insets = useSafeAreaInsets();

  const { navigation, forceHideBackButton, onBackButtonPress } = props;
  const canGoBack = props.navigation.canGoBack() && !forceHideBackButton;

  return (
    <View
      style={[ s.root, { paddingTop: insets.top, height: headerHeight + insets.top } ]}>
      <View style={[ s.left, { height: headerHeight } ]}>
        {!onBackButtonPress && (
          <HeaderBackButton
            canGoBack={canGoBack}
            goBack={navigation.goBack}
          />
        )}
        {onBackButtonPress && (
          <HeaderBackButton
            canGoBack={true}
            goBack={onBackButtonPress}
          />
        )}
      </View>
      <View style={[ s.title, { height: headerHeight, top: insets.top } ]}>
        <HeaderTitle>{props.options.title || props.route.name}</HeaderTitle>
      </View>
      <View style={[ s.right, { height: headerHeight } ]}>
        {props.options.headerRight && props.options.headerRight({})}
      </View>
    </View>
  );
};

const s = StyleSheet.create({
  root: {
    width: '100%',
    height: headerHeight,
    backgroundColor: '#fff',
    zIndex: 100,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    position: 'relative',
    borderBottomWidth: 1,
  },
  left: {
    width: 60,
    zIndex: 102,
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    flex: 1,
    alignItems: 'center',
    position: 'absolute',
    left: 0,
    justifyContent: 'center',
    width: '100%',
    zIndex: 101,
  },
  right: {
    width: '50%',
    alignItems: 'flex-end',
    justifyContent: 'center',
    zIndex: 102,
  },
});

export default Header;
