import { FC } from 'react';
import { HeaderBackButtonProps } from '@react-navigation/native-stack/src/types';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import * as React from 'react';
import ArrowLeftSvg from './ArrowLeftSvg';

interface IProps extends HeaderBackButtonProps {
  goBack(): void,
}

const HeaderBackButton: FC<IProps> = ({ goBack, canGoBack }) => {
  if (!canGoBack) {
    return null;
  }

  return (
    <TouchableOpacity onPress={goBack}>
      <View style={s.root}>
        <ArrowLeftSvg />
      </View>
    </TouchableOpacity>
  );
};

const s = StyleSheet.create({
  root: {
    paddingLeft: 20,
    paddingRight: 20,
    width: 60,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 10000,
  }
})

export default HeaderBackButton;
