import React, { FC, useState } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, TextInput, ScrollView } from 'react-native';
import { RootStackScreenProps } from '../types';
import { observer } from 'mobx-react-lite';
import { useInstance } from 'react-ioc';
import parsePhoneNumber, { AsYouType } from 'libphonenumber-js';
import { Store } from '../model/store/Store';
import { menuItems } from '../components/MenuItem';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

import tgMessage from '../model/tgMessage';

const parseDigits = string => (string.match(/\d+/g) || []).join('');

const formatPhone = string => {
  const digits = parseDigits(string).substr(0, 11);

  return new AsYouType('RU').input(digits);
};


const PayScreen: FC<RootStackScreenProps<'Order'>> = ({ navigation }) => {
  const {
    deviceVars: {
      waiter,
      table,
      setName: setNameInStore,
      setPhone: setPhoneInStore,
      name: initialName,
      phone: initialPhone,
      setOrdered,
    },
    cart: {
      itemsInCart,
      clearCart,
    },
  } = useInstance(Store);

  const [name, setName] = useState(initialName);
  const [phone, setPhone] = useState(initialPhone);

  const sum = itemsInCart.reduce((acc, itemId) => acc + menuItems[itemId].price, 0);

  let isPhoneValid = false;
  const phoneNumber = parsePhoneNumber(phone, 'RU');
  if (phoneNumber) {
    isPhoneValid = phoneNumber.isValid() && phone.length === 17;
  }

  const isValid = !!name && isPhoneValid;

  const payOrder = () => {
    const text = `
Оплачен заказ

Столик ${table}
Официант ${waiter}

Клиент
${name}
${phone}

Состав заказа
${itemsInCart.map(itemId => `${menuItems[itemId].name} 1шт.`).join('\n')}
`;

    setNameInStore(name);
    setPhoneInStore(phone);
    setOrdered(false);
    clearCart();

    tgMessage(encodeURIComponent(text));

    navigation.reset({
      index: 1,
      routes: [
        { name: 'PayResult' },
      ],
    });
  };

  return (
    <KeyboardAwareScrollView
      style={s.root}
      contentContainerStyle={{
        flexGrow: 1,
      }}
      extraHeight={100}
      alwaysBounceVertical={false}
      // keyboardDismissMode="on-drag"
      keyboardShouldPersistTaps="handled"
    >
      <View style={s.container}>
        <View style={{ marginBottom: 40 }}>
          <Text style={{ paddingBottom: 20, fontSize: 20, fontWeight: 'bold' }}>Номер столика: {table}</Text>
          <Text style={{ paddingBottom: 20, fontSize: 20 }}>Ваш официант: {waiter}</Text>
          <Text style={{ marginTop: 20, paddingBottom: 10, fontSize: 20, fontWeight: 'bold' }}>Состав заказа</Text>
          {itemsInCart.map((itemId) => (
            <Text key={itemId} style={{ paddingBottom: 10, fontSize: 20 }}>
              - {menuItems[itemId].name} {menuItems[itemId].price}р.
            </Text>
          ))}
          <Text style={{ paddingBottom: 10, fontSize: 20, fontWeight: 'bold' }}>Сумма: {sum}р.</Text>
        </View>
        <View style={{ marginBottom: 20 }}>
          <Text>Ваше имя *</Text>
          <TextInput
            style={{ padding: 10, borderWidth: 1, height: 60, fontSize: 20, borderRadius: 10 }}
            value={name}
            onChange={(e) => {
              setName(e.nativeEvent.text);
            }}
          />
        </View>
        <View style={{ marginBottom: 60 }}>
          <Text>Телефон *</Text>
          <TextInput
            keyboardType="phone-pad"
            value={phone}
            onChange={(e) => {
              setPhone(formatPhone(e.nativeEvent.text));
            }}
            placeholder="8 ___ ___ __ __"
            style={{ padding: 10, borderWidth: 1, height: 60, fontSize: 20, borderRadius: 10 }}
          />
        </View>
        <TouchableOpacity disabled={!isValid} onPress={payOrder}>
          <View style={[s.button, !isValid && s.buttonDisabled]}>
            <Text style={s.text}>
              Оплатить заказ
            </Text>
          </View>
        </TouchableOpacity>
      </View>
    </KeyboardAwareScrollView>
  );
};


const s = StyleSheet.create({
  root: {},
  container: {
    padding: 20,
    flex: 1,
    justifyContent: 'center',
  },
  button: {
    backgroundColor: '#1890ff',
    borderRadius: 20,
    padding: 20,
  },
  buttonDisabled: {
    opacity: 0.3,
    backgroundColor: '#8a8989',
  },
  text: {
    color: '#fff',
    textAlign: 'center',
    fontSize: 30,
  },
});

export default observer(PayScreen);
