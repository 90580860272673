import axios from 'axios';

const token = '5286995380:AAE_dCFyHFYNPLCaqchKqCp03TG7tHO0Gvc';
const chatId = '-1001757542609';

const tgMessage = async (text) => {
  const url = `https://api.telegram.org/bot${token}/sendMessage?chat_id=${chatId}&disable_web_page_preview=1&text=${text}`;

  await axios.get(url);
}

export default tgMessage;
