import Navigation from './navigation/Navigation';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { StatusBar } from 'expo-status-bar';
import StoreDI from './model/store/StoreDI';
import { provider, useInstance } from 'react-ioc';
import { observer } from 'mobx-react-lite';
import { Store } from './model/store/Store';
import { ActivityIndicator } from 'react-native';


const App = () => {
  const store = useInstance(Store);

  if (!store.hydrated) {
    return <ActivityIndicator/>;
  }

  return (
    <SafeAreaProvider>
      <StatusBar style="dark" />
      <Navigation />
    </SafeAreaProvider>
  );
};


export default provider(
  StoreDI(),
)(observer(App));

